<template>
  <CCard color="white" text-color="black" class="text-center" body-wrapper>
    <CRow>
      <CCol md="12" class="text-center">
        <h2 class="font-weight-normal text-dark">{{ $t('cardSetting') }}</h2>
        <h6 class="text-description">
          {{ $t('cardSettingDialog') }}
        </h6>
      </CCol>
    </CRow>
    <hr />
    <CAlert color="success" v-if="isSaveSuccess != ''" :show.sync="timer">
      {{ isSaveSuccess }}
    </CAlert>

    <div v-if="showRegister === true">
      <h5>{{ $t('noRegisterLineOA') }}</h5>
      <p>
        {{ $t('youCanRegisterAt') }}
        <strong>{{ $t('member.register') }}</strong>
        {{ $t('orCheckOutAt') }}
        <router-link :to="'/manual'" class="underline">
          {{ $t('systemUserManual') }}
        </router-link>
      </p>
    </div>

    <div v-else-if="showRegister === false">
      <div v-if="isSuspend === undefined || isSuspend === false || objectId === ''">
        <CRow>
          <CCol md="12">
            <table style="width:100%" v-if="isSaved === ''" aria-describedby="">
              <tr>
                <th scope="col" style="width:6%">
                  <h6 class="text-left">
                    <CIcon class="text-white" name="cil-settings" size="3xl"
                      style="background-color:#3abc98;padding:10px;" />
                  </h6>
                </th>
                <td>
                  <h6 class="text-left font-weight-normal dark-text">{{ $t('status') }}</h6>
                </td>
                <td class="text-right">
                  <span>
                    {{ $t('cardSettingDialog') }}
                  </span>
                </td>
                <td class="text-right" style="width:5%">
                  <CSwitch color="light" checked disabled />
                </td>
              </tr>
            </table>

            <table style="width:100%" v-else-if="isSaved === 0" aria-describedby="">
              <tr class="font-weight-normal h5">
                <th scope="col" style="width:6%">
                  <h6 class="text-left">
                    <CIcon class="text-white" name="cil-settings" size="3xl"
                      style="background-color:#3abc98;padding:10px;" />
                  </h6>
                </th>
                <td>
                  <h6 class="text-left font-weight-normal text-dark">{{ $t('status') }}</h6>
                </td>
                <td v-if="isSaved === 0" class="text-right text-dark font-weight-normal">
                  {{ $t('draft') }}
                </td>
                <td class="text-right" style="width:5%">
                  <CSwitch color="success" disabled />
                </td>
              </tr>
            </table>

            <table style="width:100%" v-else-if="isSaved === 1" aria-describedby="">
              <tr class="font-weight-normal">
                <th scope="col" style="width:6%">
                  <h6 class="text-left">
                    <CIcon class="text-white" name="cil-settings" size="3xl"
                      style="background-color:#3abc98;padding:10px;" />
                  </h6>
                </th>
                <td>
                  <h6 class="text-left text-grey">{{ $t('status') }}</h6>
                </td>
                <td style="width:60%" v-if="isSuspend === true" class="text-right text-danger">
                  <h6>{{ $t('suspendUsage') }}</h6>
                </td>
                <td class="text-right text-grey" v-else>
                  <h6>{{ $t('enable') }}</h6>
                </td>
                <td class="text-right" @click="suspendModal = true" style="width:5%">
                  <CSwitch v-if="isSuspend !== true && suspendModal === false" color="success" checked />
                  <CSwitch v-else-if="suspendModal === true" color="success" />
                  <CSwitch v-else-if="isSuspend === true" color="danger" checked />
                </td>
              </tr>
            </table>

            <div class="form-group row mt-4">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('selectUnit') }}
                <span class="text-danger font-weight-normal">*</span>
              </label>
              <div class="col-sm-9 custom-file text-left text-dark">
                <span class="text-danger">{{ validateUnit }}</span>
                <multiselect v-model="unit" :options="unitList" :placeholder="$t('selectUnit') + '...'" label="name"
                  track-by="name" class="font-weight-normal">
                </multiselect>
              </div>
            </div>

            <div class="form-group row mt-5">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('purchaseAmount') }}
                <span class="text-danger font-weight-normal">*</span>
              </label>
              <div class="col-sm-4 custom-file text-left text-dark">
                <span class="text-danger">{{ validatePointFree }}</span>
                <CInput class="text-black" type="number" placeholder="กรอกจำนวนสินค้า..." v-model.number="point">
                  <template #append-content>
                    {{ unit.name }}
                  </template>
                </CInput>
              </div>
              <label style="white-space: nowrap;" class="col-sm-1 col-form-label text-left text-dark">
                {{ $t('getFree') }}<span class="text-danger font-weight-normal">*</span>
              </label>
              <div class="col-sm-4 custom-file text-left text-dark">
                <span class="text-danger">{{ validatePointFree }}</span>
                <CInput class="text-dark " type="number" :placeholder="$t('inputFreeItem')" v-model.number="free">
                  <template #append-content>
                    {{ unit.name }}
                  </template>
                </CInput>
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('noteCrm') }}
              </label>
              <div class="col-sm-9 custom-file">
                <CTextarea v-model="note" />
              </div>
            </div>

            <div class="form-group row mt-5">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('selectProductForPointAccumulation') }}
                <span class="text-danger font-weight-dark">*</span>
              </label>
              <div class="col-sm-5">
                <h6 class="text-danger text-left">{{ validateItem }}</h6>
                <h6 class="font-weight-normal text-left text-grey">
                  {{ $t('youSelected') }} {{ itemTotal }} {{ $t('item') }}
                </h6>
              </div>
              <div class="col-sm-4 custom-file">
                <CButton variant="outline" color="dark" block @click="selectProduct">
                  + {{ $t('addProduct') }}
                </CButton>
              </div>
            </div>
          </CCol>
        </CRow>
        <hr />
        <br />
        <CRow v-if="isSaved === 0 || objectId === ''">
          <CCol md="6">
            <CButton block class="btn-outline-success text-dark font-weight-normal" @click="handlePopup(0)">
              {{ $t('saveDraft') }}
            </CButton>
          </CCol>
          <CCol md="6">
            <CButton block color="success" @click="handlePopup(1)">
              {{ $t('saveAndActive') }}
            </CButton>
          </CCol>
        </CRow>
        <CRow v-else>
          <CCol md="12">
            <CButton class="btn-block" color="success" @click="handlePopup(1)">
              {{ $t('updateAndActive') }}
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CModal :show.sync="popupModal" :title="$t('save')" color="success" centered :footer="footer">
            <h4 class="text-center text-dark" v-if="isSaved === 0">
              {{ $t('saveDraftConfirmation') }}
            </h4>
            <h4 class="text-center text-dark" v-else-if="isSaved === 1">
              {{ $t('saveCardSettingDialog') }}
            </h4>
            <template #footer-wrapper>
              <CRow class="justify-content-center mb-4">
                <CCol col="4">
                  <CButton block color="success" v-if="loadingButton === true" v-on:click="formSubmit">
                    {{ $t('save') }}
                  </CButton>
                  <CButton block color="success" v-else-if="loadingButton === false" disabled>
                    <CSpinner color="white" size="sm" /> {{ $t('save') }}
                  </CButton>
                </CCol>
                <CCol col="4">
                  <CButton block color="dark" @click="cancel()">
                    {{ $t('cancel') }}
                  </CButton>
                </CCol>
              </CRow>
            </template>
          </CModal>
        </CRow>
        <CRow>
          <CModal size="lg" :show.sync="suspendModal" centered :footer="suspendFooter">
            <div class="col-md-12">
              <h4 class="font-weight-normal text-left">{{ $t('cardSuspension') }}</h4>
              <p class="text-danger text-left font-weight-normal" v-if="suspendAlert !== ''">
                {{ suspendAlert }}
              </p>
            </div>
            <hr />

            <div class="form-group">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('endDateOfPoint') }} <span class="text-danger"> * </span>
              </label>
              <div class="col-sm-9 custom-file">
                <VueDatePicker color="#29B46B" placeholder="Choose date" :locale="getDateLocale()"
                  :visible-years-number="80" v-model="onDate" format="DD-MM-YYYY" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('cardSuspensionDate') }} <span class="text-danger"> * </span>
              </label>
              <div class="col-sm-9 custom-file">
                <VueDatePicker color="#29B46B" placeholder="Choose date" :locale="getDateLocale()"
                  :visible-years-number="80" v-model="onSuspend" format="DD-MM-YYYY" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('alertMessage') }}
                <span class="text-danger"> * </span>
              </label>
              <div class="col-sm-9 custom-file">
                <CTextarea v-model="alertMessage" type="input"> </CTextarea>
              </div>
            </div>
            <br />
            <div class="form-group text-left">
              <label class="col-sm-3 col-form-label text-left text-dark">
                <span> {{ $t('noteCrm') }}</span>
              </label>
              <div class="col-sm-12 custom-file">
                <p class="text-description">
                  {{ $t('noteCardSuspension') }}
                </p>
              </div>
            </div>

            <br />

            <div class="form-group text-center">
              <div class="col-sm-12 custom-file text-black">
                <strong>
                  <input type="checkbox" v-model="accepted" />
                  {{ $t('consent') }}
                  <span class="text-danger"> * </span>
                </strong>
                <br />
                <strong class="text-danger">
                  {{ acceptedAlert }}
                </strong>
              </div>
            </div>

            <template #footer-wrapper>
              <CRow class="justify-content-center col-md-12 mb-4">
                <CCol col="4">
                  <CButton style="width: 100%;" block color="success" v-if="loadingButton === true"
                    v-on:click="suspendCard">
                    {{ $t('suspenCard') }}
                  </CButton>
                  <CButton block color="success" v-else-if="loadingButton === false" disabled>
                    <CSpinner color="white" size="sm" /> {{ $t('cardSuspension') }}
                  </CButton>
                </CCol>
                <CCol col="4">
                  <CButton style="width: 100%;" block color="light" @click="cancel()">
                    {{ $t('cancel') }}
                  </CButton>
                </CCol>
              </CRow>
            </template>
          </CModal>
        </CRow>
      </div>
      <div v-if="isSuspend === true">
        <CRow>
          <CCol md="12">
            <table style="width:100%" aria-describedby="">
              <tr>
                <th scope="col" style="width:6%">
                  <h5 class="text-left">
                    <CIcon class="text-white" name="cil-settings" size="3xl"
                      style="background-color:#ee3e37;padding:10px;" />
                  </h5>
                </th>
                <td>
                  <h5 class="text-left font-weight-normal">{{ $t('usageStatus') }}</h5>
                </td>
                <td>
                  <h5 class="text-right text-danger font-weight-normal">
                    {{ $t('disable') }}
                  </h5>
                </td>
                <td class="text-right" style="width:5%">
                  <CSwitch color="danger" checked disabled />
                </td>
              </tr>
            </table>

            <CAlert color="danger" class="text-left mt-3">
              <div class="d-flex justify-content-between mb-4">
                <h4 class="font-weight-normal">
                  {{ $t('suspendCardSpacified') }}
                </h4>
                <CButton v-if="showButton" class="col-2" color="light" block @click="newCardModal = true">
                  <i class="fas fa-plus"></i>
                  <b>
                    {{ $t('createNewCard') }}
                  </b>
                </CButton>
              </div>

              <p>
                <b>
                  * {{ $t('endDateOfPoint') }} :
                </b>
                {{ endOfDeal }}
              </p>
              <p>
                <b>
                  * {{ $t('cardSuspensionDate') }} :
                </b>
                {{ suspendAt }}
              </p>
            </CAlert>

            <div class="form-group row mt-4">
              <label class="col-sm-3 col-form-label text-left text-black">
                <strong>
                  {{ $t('selectUnit') }}
                </strong>
              </label>
              <div class="col-sm-9 custom-file text-black">
                <CInput v-model="unit.name" :value.sync="unit.name" disabled />
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-sm-3 col-form-label text-left text-black">
                <strong>
                  {{ $t('purchaseAmount') }}
                </strong>
              </label>
              <div class="col-sm-4 custom-file text-dark">
                <CInput class="text-black" v-model.number="point" disabled>
                  <template #append-content>
                    {{ unit.name }}
                  </template>
                </CInput>
              </div>
              <label class="col-sm-1 col-form-label text-center text-dark">
                {{ $t('getFree') }}
              </label>
              <div class="col-sm-4 custom-file text-dark">
                <CInput class="text-black" v-model.number="free" disabled>
                  <template #append-content>
                    {{ unit.name }}
                  </template>
                </CInput>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('noteCrm') }}
              </label>
              <div class="col-sm-9 custom-file">
                <CTextarea v-model="note" disabled />
              </div>
            </div>
            <div class="form-group row mt-5">
              <label class="col-sm-3 col-form-label text-left text-dark">
                {{ $t('participateItems') }}
              </label>
              <div class="col-sm-5 custom-file">
                <p class="text-danger text-left">{{ validateItem }}</p>
                <p class="font-weight-normal text-left text-grey">
                  {{ $t('youSelected') }} {{ items.length }} {{ $t('item') }}
                </p>
              </div>
              <div class="col-sm-4 custom-file">
                <CButton variant="outline" color="dark" block @click="gotoItemDetail">
                  {{ $t('viewDetail') }}
                </CButton>
              </div>
            </div>

            <br />
          </CCol>
        </CRow>
      </div>
    </div>
    <CModal :show.sync="newCardModal" :title="$t(createNewCard)" color="info" centered :footer="footer">
      <h5 class="text-center">
        {{ $t('inCaseCreateNewCard') }}
      </h5>
      <h6>
        {{ $t('info01') }}
      </h6>
      <template #footer-wrapper>
        <CRow class="justify-content-center mb-4">
          <CCol col="4">
            <CButton block color="info" v-if="loadingButton === true" v-on:click="deleteCard">
              {{ $t('confirm') }}
            </CButton>
            <CButton block color="info" v-else-if="loadingButton === false" disabled>
              <CSpinner color="white" size="sm" /> {{ $t('confirm') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="dark" @click="newCardModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import pos from '@/services/pos'
import crm from '@/services/crm'
import moment from 'moment'
import util from '@/util/util'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      onDate: null,
      onSuspend: null,
      timer: 3,
      isSaveSuccess: '',
      point: 0,
      free: 0,
      note: '',
      id: '',
      objectId: '',
      items: [],
      warning: '',
      validateItem: '',
      validateUnit: '',
      validatePointFree: '',
      popupModal: false,
      footer: '',
      loadingButton: true,
      suspendModal: false,
      suspendFooter: '',
      isSaved: '',
      isSuspend: '',
      endOfDeal: null,
      suspendAt: null,
      accepted: '',
      acceptedAlert: '',
      suspendAlert: '',
      customerAlertMessage: '',
      dataUnit: '',
      unit: '',
      rewardcardObjectId: '',
      showRegister: '',
      newCardModal: false,
      showButton: false,
      dateText: moment().format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
      custom: {
        name: 'th',
        weekdays: 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัสบดี_ศุกร์_เสาร์'.split('_'),
        weekdaysShort: 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
        weekStart: 1,
        months: 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม'.split('_'),
        monthsShort: 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split('_'),
        formats: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd D MMMM YYYY HH:mm',
        },
      }

    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    alertMessage: {
      get() {
        let shopName = ''
        let suspendAt = this.suspendAt
        let endOfDeal = this.endOfDeal

        if (this.shops.length !== 0) {
          shopName = this.shops[0].shopName
        }

        if (suspendAt !== '') {
          suspendAt = moment(suspendAt)
            .locale('en')
            .format('DD/MM/YYYY')
        } else {
          suspendAt = '-'
        }

        if (endOfDeal !== '') {
          endOfDeal = moment(endOfDeal)
            .locale('en')
            .format('DD/MM/YYYY')
        } else {
          endOfDeal = '-'
        }

        let customerAlertMessage =
          this.$i18n.t('cardFromShop') + " " +
          shopName + " " +
          this.$i18n.t('validUntil') +
          suspendAt +
          this.$i18n.t('pleaseUseCard') +
          endOfDeal

        this.customerAlertMessage = customerAlertMessage
        return customerAlertMessage
      },
      set(newValue) {
        this.customerAlertMessage = newValue
      },
    },
    unitList() {
      let unit = this.dataUnit
      let detail = []

      for (let data of unit) {
        if (data.enabled === true) {
          detail.push({
            id: data.id,
            name: data.name,
            objectId: data.objectId,
          })
        }
      }
      return detail.sort(util.compare)
    },
    itemTotal() {
      return this.items.length
    },
  },
  mounted() {
    this.getRewardCardByUID()
    this.getUnit()
  },
  methods: {
    getDateLocale() {
      if (this.$i18n.locale == 'en') {
        return { lang: 'en' }
      } else {
        return { lang: this.custom }
      }
    },
    getCurrentLang() {
      return this.$i18n.locale
    },
    checkNewCardButton() {
      let chck = moment().isAfter(this.suspendAt)
      this.showButton = chck
    },
    registerShow() {
      if (this.shops.length !== 0) {
        if (
          this.shops[0].isLoyaltyEnabled === undefined ||
          this.shops[0].isLoyaltyEnabled === null
        ) {
          this.showRegister = true
        } else {
          this.showRegister = false
        }
      }
    },
    cancel() {
      if (this.isSaved === 1) {
        if (this.popupModal === true) {
          //this.isSaved = 0
          this.popupModal = false
        } else {
          this.suspendModal = false
        }
      } else if (this.isSaved === 0) {
        this.popupModal = false
      }
    },
    gotoItemDetail() {
      this.$router.push('/rewardcard/settings/products/' + this.objectId)
    },
    draftPopup() {
      this.isSaved = 1
      this.suspendModal = false
      this.popupModal = true
    },
    getUnit() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      pos
        .get('/api/v1.0/shop/' + uid + '/Unit/data/' + shopObjectId)
        .then((res) => {
          this.dataUnit = res.data.data
        })
    },
    getRewardCardByUID() {
      const uid = this.uid
      let documents = {}

      crm
        .get('/api/v1.0/' + uid + '/rewardcard/getbyuid')
        .then((response) => {
          documents = response.data.data.documents
          if (documents !== null) {
            this.items = documents.items
            this.point = documents.point
            this.free = documents.free
            this.unit = documents.unit
            this.note = documents.note
            this.id = documents.id
            this.objectId = documents.objectId

            this.isSuspend = documents.isSuspend
            this.isSaved = documents.isSaved

            if (this.isSuspend === undefined) {
              this.suspendAt = ''
              this.endOfDeal = ''
            } else {
              this.suspendAt = moment(documents.suspendAt).format('LLL')
              this.endOfDeal = moment(documents.endOfDeal).format('LLL')
              this.checkNewCardButton()
            }
          }

          this.registerShow()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    validateData() {
      if (this.unit === '') {
        this.popupModal = false
        this.validateUnit = this.$i18n.t('selectProductUnit')
      } else {
        this.validateUnit = ''
      }

      if (
        this.point === 0 ||
        this.free === 0 ||
        this.point === '' ||
        this.free === ''
      ) {
        this.popupModal = false
        this.validatePointFree = this.$i18n.t('inputProductAmount')
      } else {
        this.validatePointFree = ''
      }

      return this.validateUnit === '' && this.validatePointFree === ''
    },
    handlePopup(isSaved) {
      let validateData = this.validateData()

      //ถ้าฉบับร่างให้บันทึกได้โดยที่ยังไม่ได้เลือกสินค้า แต่ถ้าใช้งานจริงจะต้องเลือกสินค้าด้วย
      if (validateData === true) {
        if (isSaved === 0) {
          this.validateItem = ''
          this.isSaved = isSaved
          this.popupModal = true
        } else if (isSaved === 1) {
          let itemsLength = this.items.length
          if (itemsLength === 0) {
            this.popupModal = false
            this.validateItem = this.$i18n.t('selectProductPlease')
          } else {
            this.isSaved = isSaved
            this.popupModal = true
            this.validateItem = ''
          }
        }
      }
    },
    formSubmit() {
      this.loadingButton = false

      const data = {
        id: this.id,
        objectId: this.objectId,
        uid: this.uid,
        point: this.point,
        free: this.free,
        unit: this.unit,
        note: this.note,
        items: this.items,
        shops: this.shops,
        isSaved: this.isSaved,
      }

      let validate = this.validateData()

      if (validate) {
        crm({
          method: 'post',
          url: '/api/v1.0/' + data.uid + '/rewardcard/save',
          data: data,
        })
          .then((response) => {
            if (response.data.error.code === 0) {
              //บันทึกสำเร็จ
              this.loadingButton = true
              this.isSaveSuccess = this.$i18n.t('settingCompleted')
              this.popupModal = false
            } else {
              //มีบางอย่างผิดพลาด
              alert(this.$i18n.t('errorAlert'))
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        alert(this.$i18n.t('errorAlert'))
      }
    },
    suspendCard() {
      if (
        this.endOfDeal === '' ||
        this.suspendAt === '' ||
        this.alertMessage === ''
      ) {
        this.suspendAlert = this.$i18n.t('validateInput')
      } else {
        this.suspendAlert = ''
        if (moment(this.endOfDeal).isBefore(this.suspendAt)) {
          this.suspendAlert = ''
        } else {
          this.suspendAlert =
            this.$i18n.t('pleaseSelectSuspenPeriod')
        }
      }

      if (this.accepted === '' || this.accepted === false) {
        this.acceptedAlert = this.$i18n.t('pleaseApcceptTerm')
      } else if (this.accepted === true) {
        this.acceptedAlert = ''
      }

      if (this.suspendAlert === '' && this.accepted === true) {
        //อัพเดตข้อมูลทั้งหมดลงดาต้าเบส
        let data = {
          id: this.id,
          objectId: this.objectId,
          uid: this.uid,
          isSuspend: true,
          suspendAt: this.suspendAt,
          endOfDeal: this.endOfDeal,
          alertMessage: this.customerAlertMessage,
        }

        crm({
          method: 'post',
          url: '/api/v1.0/' + data.uid + '/rewardcard/save',
          data: data,
        })
          .then((response) => {
            if (response.data.error.code === 0) {
              this.isSuspend = true
              this.isSaveSuccess = this.$i18n.t('suspenCardSucces')
              this.suspendModal = false
            } else {
              //มีบางอย่างผิดพลาด
              alert(this.$i18n.t('errorAlert'))
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    selectProduct() {
      if (this.isSaved === '') {
        this.isSaved = 0
      }

      const data = {
        objectId: this.objectId,
        uid: this.uid,
        point: this.point,
        free: this.free,
        items: this.items,
        unit: this.unit,
        note: this.note,
        shops: this.shops,
        isSaved: this.isSaved,
      }

      let validate = this.validateData()
      let rewardcardObjectId = ''

      if (validate) {
        crm({
          method: 'post',
          url: '/api/v1.0/' + data.uid + '/rewardcard/save',
          data: data,
        })
          .then((response) => {
            if (response.data.error.code === 0) {
              //บันทึกสำเร็จ
              if (this.objectId !== '') {
                rewardcardObjectId = this.objectId
              } else {
                rewardcardObjectId = response.data.data.objectId[0]
              }

              this.$router.push(
                '/rewardcard/settings/products/' + rewardcardObjectId
              )
            } else {
              //มีบางอย่างผิดพลาด
              alert(this.$i18n.t('errorAlert'))
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    deleteCard() {
      this.loadingButton = false

      let data = {
        id: this.id,
        objectId: this.objectId,
        uid: this.uid,
        deleted_at: new Date(),
      }

      crm({
        method: 'post',
        url: '/api/v1.0/' + data.uid + '/rewardcard/save',
        data: data,
      })
        .then((response) => {
          if (response.data.error.code === 0) {
            this.loadingButton = true
            this.newCardModal = false
            this.$router.go()
          } else {
            alert(this.$i18n.t('errorAlert'))
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  watch: {
    onDate(newValue, oldValue) {
      this.endOfDeal = newValue
    },
    onSuspend(newValue, oldValue) {
      this.suspendAt = newValue
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
